<template>
  <div class="frendliy">
    <el-tabs type="border-card">
      <el-tab-pane label="我关注的">
        <div class="mine_follow">
          <div
            class="follow_item"
            v-for="item in mine_follow_list"
            :key="item.user_unix_id"
          >
            <img
              v-if="item.avatar"
              :src="item.avatar"
              alt=""
              onerror="../../assets/images/default_header.png"
            />
            <img v-else src="../../assets/images/default_header.png" alt="" />
            <span>{{ item.nickname }}</span>
          </div>
          <NoneData v-if="mine_follow_list.length === 0"></NoneData>
        </div>
      </el-tab-pane>
      <el-tab-pane label="关注我的" v-if="is_author">
        <div class="follow_me">
          <div
            class="follow_item"
            v-for="(item, index) in follow_me_list"
            :key="index"
          >
            <span>{{ index + 1 }}</span>
            <img
              v-if="item.avatar"
              :src="item.avatar"
              alt=""
              onerror="../../assets/images/default_header.png"
            />
            <img v-else src="../../assets/images/default_header.png" alt="" />
            <span class="name">{{ item.nickname }}</span>
          </div>
          <NoneData v-if="follow_me_list.length === 0"></NoneData>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我的师父" v-if="open_relation() && is_author">
        <div class="my_teacher" v-if="teacher_info">
          <span>当前师父信息</span>
          <div class="detail_box">
            <img
              v-if="teacher_info.avatar"
              :src="teacher_info.avatar"
              alt=""
              class="teach_avator"
            />
            <img
              v-else
              src="../../assets/images/default_header.png"
              alt=""
              class="teach_avator"
            />
            <div class="teacher_detail">
              <div class="detail_item">
                <span>用户ID：</span>
                <span>{{ teacher_info.user_unix_id }}</span>
              </div>
              <div class="detail_item">
                <span>用户名：</span>
                <span>{{ teacher_info.nickname }}</span>
              </div>
              <div class="detail_item">
                <span>拜师时间：</span>
                <span>{{ teacher_info.cre_time }}</span>
              </div>
              <div class="detail_item">
                <span>拜师时长：</span>
                <span>{{ teacher_info.day_time }}</span>
              </div>
            </div>
            <div class="get_phone">
              <el-button
                type="primary"
                @click="get_master_phone(teacher_info.phone)"
                >获取师父联系方式</el-button
              >
            </div>
          </div>
          <span>往期拜师记录</span>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column
              type="cover_img"
              label="用户头像"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <div class="cover_img_box">
                  <img
                    v-if="scope.row.avatar"
                    :src="scope.row.avatar"
                    alt=""
                    class="cover_img"
                  />
                  <img
                    v-else
                    src="../../assets/images/default_header.png"
                    alt=""
                    class="cover_img"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="用户ID" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span class="shelf_item_top">{{
                      scope.row.user_unix_id
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="new_push" label="用户名" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span class="shelf_item_top">{{ scope.row.nickname }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="read" label="拜师时间" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span class="shelf_item_top">{{ scope.row.cre_time }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="long_time" label="拜师时长" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span class="shelf_item_top">{{ scope.row.day_time }} 天</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="read" label="状态" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span
                      class="shelf_item_top"
                      v-text="
                        scope.row.status === 0
                          ? '已过期'
                          : scope.row.status === 1
                          ? '正常'
                          : scope.row.status === 2
                          ? '等待中'
                          : '拒绝'
                      "
                    ></span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我的徒弟" v-if="open_relation() && is_author">
        <div class="apprentice">
          <span>注意：建立师徒关系后，你的徒弟将会获得你的手机号哦！</span>
          <el-table :data="my_apprentice" style="width: 100%">
            <el-table-column
              type="cover_img"
              label="用户头像"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <div class="cover_img_box">
                  <img
                    v-if="scope.row.avatar"
                    :src="scope.row.avatar"
                    alt=""
                    class="cover_img"
                  />
                  <img
                    v-else
                    src="../../assets/images/default_header.png"
                    alt=""
                    class="cover_img"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="用户ID" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span class="shelf_item_top">{{
                      scope.row.user_unix_id
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="new_push" label="用户名" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span class="shelf_item_top">{{ scope.row.nickname }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="read" label="拜师时间" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span class="shelf_item_top">{{ scope.row.cre_time }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="read" label="关系状态" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item">
                    <span
                      class="shelf_item_top"
                      v-text="
                        scope.row.status === 0
                          ? '已过期'
                          : scope.row.status === 1
                          ? '正常'
                          : scope.row.status === -1
                          ? '拒绝'
                          : '申请中'
                      "
                    ></span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="long_time" label="操作" align="center">
              <template slot-scope="scope">
                <div class="out_box">
                  <div class="shelf_item" v-show="scope.row.status === 2">
                    <span
                      class="shelf_item_top shelf_item_resolve"
                      @click.once="user_resolve(scope.row)"
                    >
                      {{ scope.row.operate.resolve }}
                    </span>
                    <span
                      class="shelf_item_btm shelf_item_reject"
                      @click.once="user_reject(scope.row)"
                    >
                      {{ scope.row.operate.reject }}
                    </span>
                  </div>
                </div>
              </template></el-table-column
            >
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { Marsts, AcceptMaster } from "@/https/mine_center.js";
export default {
  name: "",
  components: {},
  data() {
    return {
      mine_follow_list: [],
      follow_me_list: [],
      teacher_info: {
        // avator:require("@/assets/images/hear_01.jpg"),
        // nick_name:'xiaoxiaoming',
        // time:'2020/1/1 12:12',
        // long_time:'2个月',
        // user_id:15
      },
      tableData: [],
      my_apprentice: [
        // {
        //   avatar: require("@/assets/images/hear_01.jpg"),
        //   nickname: "admin",
        //   day_time: "2020/08/12",
        //   cre_time: "2020/08/12",
        //   status: 0,
        // },
      ],
      is_author: this.is_author(),
    };
  },
  methods: {
    // 刷新当前组件
    aviod_page() {
      this.$forceUpdate();
    },
    // 获取数据
    get_data(index) {
      let params = {
        table: index,
        id: JSON.parse(sessionStorage.getItem('person_info')).id,
      };
      Marsts(params).then((res) => {
        if (index === 1) {
          this.mine_follow_list = res.data ? res.data : [];
        } else if (index === 2) {
          // 正常
          this.follow_me_list = res.data ? res.data : [];
        } else if (index === 3) {
          // 正常
          this.teacher_info = res.data.my_master ? res.data.my_master : [];
          this.tableData = res.data.master_history
            ? res.data.master_history
            : [];
        } else if (index === 4) {
          // console.log(res)
          this.my_apprentice = res.data.apprent_history
            ? res.data.apprent_history
            : [];
          let operate = {
            resolve: "接受",
            reject: "拒绝",
          };
          this.my_apprentice.forEach((val) => {
            val.operate = operate;
          });

        }
      });
    },
    // 复制师父手机号
    get_master_phone(phone) {
      if (phone) {
        var input = document.createElement("input");
        input.value = phone;
        input.readOnly = true;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length);
        document.execCommand("Copy");
        document.body.removeChild(input);
        this.$message({
          message: "师父联系方式已复制到粘贴板",
          type: "success",
        });
      } else {
        this.$message({
          message: "暂无师父联系方式哦",
          type: "warning",
        });
      }
    },
    // 接受拜师申请
    user_resolve(row) {
      this.$confirm(`您确认要接受${row.nickname}的拜师申请吗?`, "重要提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 提交申请接口
          AcceptMaster({
            id: row.id,
            type: 1,
          }).then((res) => {
            if (res.code === 0) {
              row.operate.resolve = "已接受";
              row.operate.reject = "";
              this.get_data(4);
              this.get_data(4);
              this.$message({
                type: "success",
                message: `您已接受${row.nickname}的拜师申请!`,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "您已取消操作",
          });
        });
    },
    // 拒绝表示申请
    user_reject(row) {
      this.$confirm(`您确认要拒绝${row.nickname}的拜师申请吗?`, "重要提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 拒绝申请接口
          AcceptMaster({
            id: row.id,
            type: 2,
          }).then((res) => {
            if (res.code === 0) {
              row.operate.reject = "已拒绝";
              row.operate.resolve = "";
              this.get_data(4);
              this.get_data(4);
              this.$message({
                type: "warning",
                message: `您已拒绝${row.nickname}的拜师申请!`,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "您已取消操作",
          });
        });
    },
  },
  async created() {
    await this.get_data(1);
    await this.get_data(2);
    await this.get_data(3);
    await this.get_data(4);
  },
  async mounted() {
    // await this.get_data(1);
    // console.log(this.tableData,this.teacher_info,this.mine_follow_list,this.follow_me_list,this.my_apprentice)
  },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.frendliy {
  // box-shadow: 0 0 5px #e4e4e4;
}
.detail_item {
  min-width: 150px;
}
/deep/ .el-tabs--border-card {
  border: none;
  box-shadow: none;
}
.mine_follow {
  @include flex-align-center();
  flex-wrap: wrap;
  width: 100%;
  .follow_item {
    @include flex-center();
    padding: 20px 0;
    width: 33.33%;
    border-bottom: 1px solid#e4e4e4;
    img {
      width: 50px;
      height: 50px;
    }
    span {
      margin-left: 20px;
    }
  }
}
.un_show_item {
  width: 100%;
  height: 100%;
  color: #999;
  img {
    width: 80%;
    height: 80%;
    opacity: 0.5;
  }
  p {
    position: relative;
    top: -100px;
  }
}
.follow_me {
  @include flex-align-center();
  flex-wrap: wrap;
  .follow_item {
    @include flex-center();
    padding: 20px 0;
    width: 33.33%;
    border-bottom: 1px solid#e4e4e4;
    img {
      width: 50px;
      height: 50px;
    }
    span:nth-child(1) {
      margin-right: 10px;
    }
    .name {
      margin-left: 20px;
    }
  }
}

.my_teacher {
  .teach_avator {
    width: 150px;
    height: 150px;
  }
  .teacher_detail {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    margin-left: 30px;
  }
  .detail_box {
    padding: 15px;
    @include flex-row();
  }
  .cover_img_box {
    width: 100%;
    @include flex-center();
    .cover_img {
      width: 60px;
      height: 60px;
    }
  }
  .get_phone {
    height: 150px;
    display: flex;
    margin-left: 30px;
    /deep/.el-button {
      padding: 8px 15px;
      margin-top: auto;
      align-items: flex-end;
    }
  }
}
.apprentice {
  .cover_img_box {
    width: 100%;
    @include flex-center();
    .cover_img {
      width: 60px;
      height: 60px;
    }
  }
  .shelf_item_reject,
  .shelf_item_resolve {
    cursor: pointer;
    margin: 5px;
  }
  .shelf_item_reject:hover,
  .shelf_item_resolve:hover {
    color: #49a7ff;
  }
}
.nothing_pic {
  width: 100%;
}
</style>
